<template>
  <div>
    <b-card class="p-3">
      <template v-slot:header>
        <h3 class="py-1 text-muted font-weight-bold main-title-details">{{ $t('auth.userInformation') }}</h3>
      </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form class="position-relative form-top p-4" @submit.prevent="handleSubmit(editUser)">
        <b-row>
          <b-col lg="12">
            <div class="userProfile-img-wrapper d-flex justify-content-center align-items-center mb-5 imgDiv position-relative">
              <input type="file" class="d-none" :id="`profile-uploader-user-profile`" :name="`file-uploader-profile-uploader-user-profile${!loading ? '-dispabled' : ''}`" @change="addFile"/>
              <label class="userProfile-img-label m-0 w-100 h-100 position-relative" :for="`profile-uploader-user-profile`">
                <!-- <img :src="currentDoc" v-if="currentDoc" class="img-fluid"/>
                <img src="@/assets/images/ibbil/man-saudi.jpg" v-else class="img-fluid w-100 h-100"/> -->
                <div class="userProfile-img"
                :style="{
                  'background-image': 'url(' + currentDoc + ')',
                  'background-size' :  'cover',
                  'background-repeat': 'no-repeat'
                }">
                </div>
                <div class="position-absolute align-items-center justify-content-center editUserImg" v-if="!loading">
                  <span class="text-white font-size-16">{{ $t('auth.change') }}</span>
                </div>
                <div class="editUserImg loadingEditImg position-absolute align-items-center justify-content-center" v-else>
                  <b-spinner label="Spinning" variant="primary"></b-spinner>
                </div>
              </label>
            </div>
          </b-col>
          <b-col lg="6">
            <input-form
                class="mb-3"
                v-model="national_id"
                :validate=" { required: true, numeric:true, digits:10,  regex: /^[1-2][0-9]*$/}"
                :placeholder="$t('auth.IdNumber')"
                :name="$t('auth.IdNumberWithCondition')"
                :label="$t('auth.IdNumber')"
                disabled
            />
          </b-col>
          <b-col lg="6">
            <country-code
                :placeholder="$t('auth.phoneNumber')"
                :name="$t('auth.phoneNumber')"
                :label="$t('auth.phoneNumber')"
                v-model="phone"
                id="phone-code-primary_phone_number"
                @onSelect="onSelect"
                disabled
            />
<!--            <input-form-->
<!--                class="mb-3"-->
<!--                v-model="phone"-->
<!--                :validate="'required|numeric|min:9|max:11'"-->
<!--                :placeholder="$t('auth.phoneNumber')"-->
<!--                :name="$t('auth.phoneNumber')"-->
<!--                :label="$t('auth.phoneNumber')"-->
<!--                disabled-->
<!--            />-->
          </b-col>
          <b-col lg="6">
            <input-form
                class="mb-3"
                v-model="userInfo.name"
                :validate="'required'"
                :placeholder="$t('auth.userName')"
                :name="$t('auth.userName')"
                :label="$t('auth.userName')"
            />
          </b-col>
          <b-col lg="6">
            <input-form
                class="mb-3"
                v-model="userInfo.email"
                :validate="'required'"
                :placeholder="$t('auth.email')"
                :name="$t('auth.email')"
                :label="$t('auth.email')"
                type="email"
            />
          </b-col>
          <b-col lg="6">
            <input-form
                class="mb-3"
                v-model="userInfo.address"
                :validate="'required'"
                :placeholder="$t('auth.address')"
                :name="$t('auth.address')"
                :label="$t('auth.address')"
            />
          </b-col>
          <!-- <b-col md="6" >
            <input-form v-model="userInfo.iban"  :name='`IBAN`' label="رقم IBAN" type="text"  :validate=" { required: true, regex: /^['SA']{2}[0-9]{22}$/}"></input-form>
          </b-col> -->
          <b-col lg="12">
            <div class="d-flex justify-content-center mt-4" >
              <b-button class="text-white w-20 py-2" variant="primary" type="submit" v-if="!submitLoading">
                <span>{{ $t('auth.save') }}</span>
              </b-button>
              <b-button v-else class=" text-white w-20 py-2" variant="primary" disabled>
                <spinner-loading text="saving"/>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
    </b-card>
    <b-card class="p-3 my-5">
      <template v-slot:header>
        <h3 class="py-1 text-muted font-weight-bold main-title-details">{{ $t('auth.changePassword') }}</h3>
      </template>
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form class="position-relative form-top p-4" @submit.prevent="handleSubmit(changePassword)">
          <b-row>
            <b-col lg="6">
              <input-form
                  class="mb-3"
                  v-model="userPassword.password"
                  :validate="'required'"
                  :placeholder="$t('auth.currentPassword')"
                  :name="$t('auth.currentPassword')"
                  :label="$t('auth.currentPassword')"
                  type="password"
              />
            </b-col>
          </b-row>
            <b-row>
            <b-col lg="6">
              <input-form
                  class="mb-3"
                  v-model="userPassword.new_password"
                  :validate="'required|min:8'"
                  :placeholder="$t('auth.newPassword')"
                  name="newPassword"
                  :label="$t('auth.newPassword')"
                  type="password"
              />
            </b-col>
            <b-col lg="6">
              <input-form
                  class="mb-3"
                  v-model="userPassword.confirm_password"
                  :validate="'required|confirmed:newPassword'"
                  :placeholder="$t('auth.passwordChecked')"
                  :name="$t('auth.passwordChecked')"
                  :label="$t('auth.passwordChecked')"
                  type="password"
              />
            </b-col>
            <!-- <b-col md="6" >
              <input-form v-model="userInfo.iban"  :name='`IBAN`' label="رقم IBAN" type="text"  :validate=" { required: true, regex: /^['SA']{2}[0-9]{22}$/}"></input-form>
            </b-col> -->
            <b-col lg="12">
              <div class="d-flex justify-content-center mt-4" >
                <b-button class="text-white w-20 py-2" variant="primary" type="submit" v-if="!submitLoading">
                  <span>{{ $t('auth.save') }}</span>
                </b-button>
                <b-button v-else class=" text-white w-20 py-2" variant="primary" disabled>
                  <spinner-loading text="saving"/>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
// import authService from '@/modules/auth/services/auth'
import { core } from '@/config/pluginInit'
import api from '../services/userProfile'

export default {
  name: 'userSettings',
  data () {
    return {
      currentDoc: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.avatar : '',
      loading: false,
      submitLoading: false,
      national_id: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.national_id : '',
      phone: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.phone : '',
      country_code: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.country_code : '',
      userInfo: {
        avatar: '',
        name: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.name : '',
        email: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.email : '',
        address: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.address : ''
        // iban: ''
      },
      userPassword: {
        password: '',
        new_password: '',
        confirm_password: ''
      }
    }
  },
  methods: {
    onSelect (data) {
      this.country_code = data
    },
    addFile (e) {
      this.loading = true
      const targetFile = e.target.files[0]
      // this.currentDoc = window && window.URL.createObjectURL(targetFile)
      const formData = new FormData()
      formData.append('file', targetFile)
      formData.append('app_name', 'website')
      formData.append('directory', 'users')
      api.uploadProfile(formData).then(res => {
        this.userInfo.avatar = res.data.url
        this.currentDoc = res.data.url
      }).finally(() => {
        this.loading = false
      })
    },
    editUser () {
      this.submitLoading = true
      api.editUserProfile(this.userInfo).then(res => {
        localStorage.setItem('userInfo', JSON.stringify({ ...JSON.parse(localStorage.getItem('userInfo')), user: res.data.data }))
        core.showSnackbar('success', res.data.message)
        window.location.reload()
      }).finally(() => {
        this.submitLoading = false
      })
    },
    changePassword () {
      this.submitLoading = true
      api.changePassword(this.userPassword).then(res => {
        core.showSnackbar('success', res.data.message)
        localStorage.clear()
        window.location.reload()
      }).finally(() => {
        this.submitLoading = false
        this.$router.push({ name: 'login' })
      })
    }
    // editUser () {
    //   if (this.code === '00966' && this.newUser.phone.charAt(0) === '0') {
    //     this.fullNumber = this.newUser.phone.slice(1)
    //     console.log(this.fullNumber)
    //   } else {
    //     this.fullNumber = this.newUser.phone
    //     console.log(this.fullNumber)
    //   }

    // authService.register({ ...this.newUser, phone: this.code + this.fullNumber }).then(res => {
    //   core.showSnackbar('success', res.data.message)
    //   localStorage.setItem('userInfoRegistration', JSON.stringify(res.data.user))
    //   this.$router.push({
    //     name: 'otb',
    //     params: {
    //       phoneNumber: res.data.user.phone // 0566502430
    //     }
    //   })
    //   // authService.createTransportationUser(res.data.user).then(userTrans => {
    //   //
    //   // })
    // }).finally(() => {
    //   this.loadingRegistrationButton = false
    // })
  }
  //   onSelect ({ name, dialCode }) {
  //     const countryCode = {
  //       20: '002',
  //       966: '00966'
  //     }
  //     this.code = countryCode[dialCode]
  //   }
  // }
}
</script>

<style>
.userProfile-img-wrapper {
  cursor: pointer;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: auto;
  overflow: hidden;
}
.userProfile-img{
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-color: #d2cece;
  background-repeat: no-repeat;
}
.userProfile-img-wrapper:hover .editUserImg{
display: flex;
}
.editUserImg{
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgba(115, 115, 115, 0.8);
  display: none;
}
</style>
0
